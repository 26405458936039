export const ENV_NAMES = {
  local: 'local',
  development: 'development',
  staging: 'staging',
  production: 'production'
} as const

export type EnvironmentalVariables<T = string> = {
  [key in keyof typeof ENV_NAMES]: T
}

export const FIREBASE_PROPS: EnvironmentalVariables<
  import('firebase/app').FirebaseOptions
> = {
  local: {
    apiKey: 'AIzaSyBX64fIcZmKuTrK-6U7hyjog4FwAwV0Wao',
    authDomain: 'smooth-prototype-development.firebaseapp.com',
    databaseURL: 'https://smooth-prototype-development.firebaseio.com',
    projectId: 'smooth-prototype-development',
    storageBucket: 'smooth-prototype-development.appspot.com',
    messagingSenderId: '491201020682',
    appId: '1:491201020682:web:3abd19e0f6e791733f70f3',
    measurementId: 'G-7WEFPPVBYG'
  },
  staging: {
    apiKey: 'AIzaSyCDQi4mJxwTiqD-I2Vga1CQXHvGKQt-mZo',
    authDomain: 'smooth-staging-eb1b7.firebaseapp.com',
    projectId: 'smooth-staging-eb1b7',
    storageBucket: 'smooth-staging-eb1b7.appspot.com',
    messagingSenderId: '791240961446',
    appId: '1:791240961446:web:0fdfb63d197f86e101b367',
    measurementId: 'G-7FY629TXSD'
  },
  development: {
    apiKey: 'AIzaSyBX64fIcZmKuTrK-6U7hyjog4FwAwV0Wao',
    authDomain: 'smooth-prototype-development.firebaseapp.com',
    databaseURL: 'https://smooth-prototype-development.firebaseio.com',
    projectId: 'smooth-prototype-development',
    storageBucket: 'smooth-prototype-development.appspot.com',
    messagingSenderId: '491201020682',
    appId: '1:491201020682:web:3abd19e0f6e791733f70f3',
    measurementId: 'G-7WEFPPVBYG'
  },
  production: {
    apiKey: 'AIzaSyAaj1son6YCjoSMaSLI7QcAkeUnLWRpBHk',
    authDomain: 'smooth-prod.firebaseapp.com',
    databaseURL: 'https://smooth-prod.firebaseio.com',
    projectId: 'smooth-prod',
    storageBucket: 'smooth-prod.appspot.com',
    messagingSenderId: '645807837050',
    appId: '1:645807837050:web:54387c370be6c0180a10c3',
    measurementId: 'G-KRGKV8CNHG'
  }
}

export const SMOOTH_PARTNER_API_URLS: EnvironmentalVariables = {
  local: 'http://localhost:13001/base_api/partner',
  development: 'https://api.app.dev.smooth.jp/base_api/partner',
  staging: 'https://api.app.staging.smooth.jp/base_api/partner',
  production: 'https://api.app.smooth.jp/base_api/partner'
}

export const DATADOG_PARAMS = {
  applicationId: '3a60c38b-86bd-47a6-8c7d-d520a5f54a3c',
  clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
  site: 'ap1.datadoghq.com',
  service: 'smooth_partner',
  env: process.env.ENV_NAME,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input' as const
}
