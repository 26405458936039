import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApplicationConditionsFormState } from 'src/components/organisms/ApplicationTableSection/ApplicationTableSectionConditions'
import { PaginationState } from 'src/features/pagination'

type ApplicationsState = {
  applicationConditionsFormState: ApplicationConditionsFormState
  applicationsPagination: PaginationState
  propertiesPagination: PaginationState
  shouldReloadAllApplications: boolean
}

const initialState: ApplicationsState = {
  applicationConditionsFormState: {
    filter: {
      unapproved: false
    },
    search: '',
    order: 'desc',
    sort: 'schedules.created_at'
  },
  applicationsPagination: {
    page: 1
  },
  propertiesPagination: {
    page: 1
  },
  shouldReloadAllApplications: true
}

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    updateApplicationConditionsFormState: (
      state,
      action: PayloadAction<ApplicationsState['applicationConditionsFormState']>
    ) => {
      state.applicationConditionsFormState = action.payload
    },
    updateApplicationsPagination: (
      state,
      action: PayloadAction<ApplicationsState['applicationsPagination']>
    ) => {
      state.applicationsPagination = action.payload
    },
    updatePropertiesPagination: (
      state,
      action: PayloadAction<ApplicationsState['applicationsPagination']>
    ) => {
      state.propertiesPagination = action.payload
    },
    updateShouldReloadAllApplications: (
      state,
      action: PayloadAction<ApplicationsState['shouldReloadAllApplications']>
    ) => {
      state.shouldReloadAllApplications = action.payload
    },
    reset: () => initialState
  }
})

export const {
  updateApplicationConditionsFormState,
  updateApplicationsPagination,
  updatePropertiesPagination,
  updateShouldReloadAllApplications,
  reset
} = applicationsSlice.actions
