import { datadogRum } from '@datadog/browser-rum'
import { AppProps } from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import { Logger } from 'src/features/log'
import { DATADOG_PARAMS } from 'src/lib/constants/values'
import { store } from 'src/store/store'

if (process.env.ENV_NAME === 'local') {
  const MockServer = () => require('../../mocks/worker')
  MockServer()
}

const App = ({ Component, pageProps }: AppProps) => {
  React.useEffect(() => {
    datadogRum.init(DATADOG_PARAMS)
  }, [])

  return (
    <Provider store={store}>
      <Logger>
        <Component {...pageProps} />
      </Logger>
    </Provider>
  )
}

export default App
