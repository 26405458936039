import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

type Props = {
  className?: string
  children?: React.ReactNode
}

export const Logger = React.memo<Props>((props) => {
  const uidSelector = useSelector((state) => state.auth.user?.uid)
  const storeIdSelector = useSelector((state) => state.auth.claims?.store_id)
  const [initialized, setInitialized] = useState(false)
  const [identified, setIdentified] = useState(false)

  const initialize = useCallback(() => {
    if (initialized) return
    setInitialized(true)
  }, [initialized])

  const identify = useCallback(() => {
    if (uidSelector && storeIdSelector && !identified) {
      setIdentified(true)
    }
  }, [identified, storeIdSelector, uidSelector])

  useEffect(() => {
    initialize()
    identify()
  }, [identify, initialize])

  return <>{props.children}</>
})
